export default {
    namespaced: true,
    state: {
        page: null,
        currentPage: null,
        pageId: null,
    },
    mutations: {
        SET_PAGE(state, data) {
            state.page = data
        },
        SET_CURRENT_PAGE(state, data) {
            state.currentPage = data
        },
        SET_PAGE_ID(state, data) {
            state.pageId = data
        }
    },
    actions: {
        setPage({commit}, page) {
            commit('SET_PAGE', page);
        },
        setCurrentPage({commit}, page) {
            commit('SET_CURRENT_PAGE', page);
        },
        setPageId({commit}, id) {
            commit('SET_PAGE_ID', id);
        }
    }
}