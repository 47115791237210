import {isApiResponseValid} from "@/helpers/helpers";
import {coursesService} from "@/services/courses.service";
import {AppError} from "@/services/errors";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        difficulty: localStorage.getItem("coursesFilterDifficulty") || '0',
        courses: [],
    },
    mutations: {
        SET_COURSES(state, data) {
            state.courses = data
        },
        SET_DIFFICULTY(state, data) {
            state.difficulty = data
        },
    },
    actions: {
        setDifficulty({commit, dispatch}, difficulty) {
            commit('SET_DIFFICULTY', difficulty);
            localStorage.setItem("coursesFilterDifficulty", difficulty);
            dispatch('fetchData')
        },
        async fetchData({commit, state}) {
            try {
                const response = await coursesService.getAll(state.difficulty);

                if (!isApiResponseValid(response)) {
                    throw new AppError('Error loading data', {"message": 'Chyba při načítání kurzů, zkuste obnovit stránku.', 'type': 'warning'})
                }

                commit('SET_COURSES', response.data);
            } catch (err) {
                if (err instanceof AppError && err.data) {
                    store.dispatch('app/addFlashMessage', err.data)
                } else {
                    throw err;
                }
            }
        }
    }
}