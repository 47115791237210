import {alertMessages} from "@/services/alertMessage";

export default {
    namespaced: true,
    state: {
        isMobileNavigationActive: false,
        flashMessages: null,
        alertMessages: alertMessages.getAll(),
    },
    getters: {
        getFlashMessages: (state) => {
            let out = state.flashMessages;

            if (out) {
                out = JSON.parse(out);
            } else {
                out = {}
            }

            return out;
        }
    },
    mutations: {
        SET_FLASH_MESSAGES(state, data) {
            state.flashMessages = JSON.stringify(data)
        },
        SET_ALERT_MESSAGES(state, data) {
            state.alertMessages = data
        },
        SET_MOBILE_NAVIGATION_STATE(state, data) {
            state.isMobileNavigationActive = data
        }
    },
    actions: {
        addFlashMessage({commit, getters}, data) {
            let currentFlashMessages = getters.getFlashMessages;
            const id = Date.now();

            currentFlashMessages[id] = data;

            commit("SET_FLASH_MESSAGES", currentFlashMessages)
        },
        removeFlashMessage({commit, getters}, id) {
            setTimeout(() => {
                let currentFlashMessages = getters.getFlashMessages;

                delete currentFlashMessages[id];

                commit("SET_FLASH_MESSAGES", currentFlashMessages)
            }, 5000)
        },
        addAlertMessage({}, data) {
            alertMessages.add(data)
        },
        removeAlertMessage({commit}, id) {
            alertMessages.remove(id)
            setTimeout(() => {
                commit("SET_ALERT_MESSAGES", alertMessages.getAll())
            }, 10000)
        },
        toggleMobileNavigation({commit, state}) {
            commit("SET_MOBILE_NAVIGATION_STATE", !state.isMobileNavigationActive)
        }
    }
}