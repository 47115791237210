import {isApiResponseValid} from "@/helpers/helpers";
import {lectureService} from "@/services/lectures.service";
import {AppError} from "@/services/errors";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        type: localStorage.getItem("trainingsFilterType") || '0',
        order: localStorage.getItem("trainingsFilterOrder") || '1',
        trainings: {},
    },
    mutations: {
        SET_TRAININGS(state, data) {
            state.trainings = data
        },
        SET_TYPE(state, data) {
            state.type = data
        },
        SET_ORDER(state, data) {
            state.order = data
        }
    },
    actions: {
        setType({commit, dispatch}, type) {
            commit('SET_TYPE', type);
            localStorage.setItem("trainingsFilterType", type);
            dispatch('fetchData')
        },
        setOrder({commit, dispatch}, order) {
            commit('SET_ORDER', order);
            localStorage.setItem("trainingsFilterOrder", order);
            dispatch('fetchData')
        },
        async fetchData({commit, state}) {
            try {
                const response = await lectureService.getAllUpcoming(1, state.type, state.order);
                if (!isApiResponseValid(response)) {
                    throw new AppError('Error loading data', {"message": 'Chyba při načítání kurzů, zkuste obnovit stránku.', 'type': 'warning'})
                }

                commit('SET_TRAININGS', response.data);
            } catch (err) {
                if (err instanceof AppError && err.data) {
                    store.dispatch('app/addFlashMessage', err.data)
                } else {
                    throw err;
                }
            }
        }
    }
}