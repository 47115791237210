import {codebooksService} from "@/services/codebooks.service";

export default {
    namespaced: true,
    state: {
        translations: {},
        codebooks: {},
    },
    getters: {
        getTranslation: (state) => (placeholder, attrs) => {
            return codebooksService.translation(state.translations, placeholder, attrs)
        },
        getCodeBookByKey: (state) => (key) => {
            return codebooksService.getCodeBookByKey(state.codebooks, key)
        },
        getCodeBookByKeyAndValue: (state) => (key, value) => {
            return codebooksService.getCodeBookByKeyAndValue(state.codebooks, key, value)
        }
    },
    mutations: {
        SET_TRANSLATION(state, data) {
            state.translations = data
        },
        SET_CODEBOOKS(state, data) {
            state.codebooks = data
        }
    },
    actions: {
        setAllCodebooks({commit}, {endpoint, data}) {
            if (endpoint === 'codebooks') {
                commit("SET_CODEBOOKS", data);
            } else if (endpoint === 'translations') {
                commit("SET_TRANSLATION", data);
            } else {

            }
        }
    }
}