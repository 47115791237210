import {authenticationService} from "@/services/authentication";
import {userService} from "@/services/user.service";

export default {
    namespaced: true,
    state: {
        userData: null,
    },
    getters: {
        loggedIn() {
            return !!authenticationService.getAxiosToken()
        },
        getCompletedAssessments: (state) => {
            return state.userData?.assessments_completed || [];
        },
        userRole: (state) => {
            return state.userData.role;
        }
    },
    mutations: {
        SET_USER(state, data) {
            state.userData = data
        }
    },
    actions: {
        async getUser({commit, getters}, getFromStorage = true) {
            if (getters.loggedIn) {
                commit('SET_USER', await userService.getCurrentUser(getFromStorage));
            }
        }
    }
}