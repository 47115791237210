import {isApiResponseValid} from "@/helpers/helpers";
import {studyProgramsService} from "@/services/studyPrograms.service";
import {AppError} from "@/services/errors";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        studyPrograms: [],
    },
    mutations: {
        SET_STUDY_PROGRAMS(state, data) {
            state.studyPrograms = data
        },
    },
    actions: {
        async fetchData({commit}) {
            try {
                const response = await studyProgramsService.getAllSimple();

                if (!isApiResponseValid(response)) {
                    throw new AppError('Error loading data', {
                        "message": 'Chyba při načítání studijních programmů, zkuste obnovit stránku.',
                        'type': 'warning'
                    })
                }

                commit('SET_STUDY_PROGRAMS', response.data);
            } catch (err) {
                if (err instanceof AppError && err.data) {
                    store.dispatch('app/addFlashMessage', err.data)
                } else {
                    throw err;
                }
            }
        },
    }
}