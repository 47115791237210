import {enrollmentsService} from "@/services/enrollments.service";
import {coursesService} from "@/services/courses.service";
import {isApiResponseValid} from "@/helpers/helpers";
import {AppError} from "@/services/errors";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        currentVideo: null,
        currentCourse: null,
        currentCourseId: null,
        isPlaying: false
    },
    mutations: {
        SET_VIDEO(state, data) {
            state.currentVideo = data
            state.isPlaying = false
        },
        SET_COURSE(state, data) {
            state.currentCourse = data;
        },
        SET_COURSE_ID(state, data) {
            state.currentCourseId = data;
        },
        SET_PLAY(state) {
            state.isPlaying = true
        },
        SET_PAUSE(state) {
            state.isPlaying = false
        }
    },
    actions: {
        setVideo({commit}, data) {
            commit('SET_VIDEO', data);
        },
        setCourse({commit}, data) {
            commit('SET_COURSE', data);
        },
        setCourseId({commit}, data) {
            commit('SET_COURSE_ID', data);
        },
        async activateLecture({commit, state}, lectureId) {
            // Enroll user to lecture
            await enrollmentsService.lectureEnrollment({"lecture_id": lectureId});

            // Get current lecture data because of last video position
            const response = await enrollmentsService.getLectureData(lectureId);
            let lecture = response.data;

            // Init values for current video
            let currentVideo = state.currentVideo;
            currentVideo.lectureId = lectureId;
            currentVideo.sectionId = lecture.section_id;
            currentVideo.nextLectureId = lecture.id_next;
            currentVideo.lastVideoPosition = lecture.last_video_position;
            currentVideo.lectureDateFinished = lecture.date_finished;
            currentVideo.videoSourceUrl = lecture.video ? lecture.video.url : null;
            currentVideo.lectureDurationSeconds = lecture.video ? lecture.video.duration_seconds : null;
            currentVideo.userLectureId = lecture.user_lecture_id;
            currentVideo.lectureDescription = lecture.description;
            currentVideo.lectureDescriptionShort = lecture.description_short;
            currentVideo.lectureName = lecture.name;
            currentVideo.lectureFaq = lecture.faq;

            commit('SET_VIDEO', currentVideo);
        },
        play({commit}) {
            commit('SET_PLAY');
        },
        pause({commit}) {
            commit('SET_PAUSE');
        },
        async getCurrentCourse({commit, state}) {
            try {
                const response = await coursesService.getCourse(state.currentCourseId);

                if (!isApiResponseValid(response)) {
                    throw new AppError('Error loading data', {"message": 'Chyba při načítání kurzů, zkuste obnovit stránku.', 'type': 'warning'})
                }

                commit('SET_COURSE', response.data);
            } catch (err) {
                if (err instanceof AppError && err.data) {
                    store.dispatch('app/addFlashMessage', err.data)
                } else {
                    throw err;
                }
            }
        },
    }
}